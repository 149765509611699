import {FC, useState} from "react";
import {useSelector} from "react-redux";
import {A11yButton} from "components/common/buttons/A11yButton";
import {DeliveryConflictLabel} from "components/common/labels/DeliveryConflictLabel";
import {DeliveryShiftLabel} from "components/common/labels/DeliveryShiftLabel";
import {orderSelectors} from "state/redux/slices/order";
import {checkOOBO} from "utils/ooboHelpers";
import {DELIVERY_WINDOW_BUTTON, PICKUP_WINDOW_BUTTON} from "constants/e2e/testIds";
import {SCHEDULE_TABS} from "constants/order";
import {ReactComponent as PencilIcon} from "assets/images/Icon_Edit.svg";
import {DICTIONARY} from "../assets/dictionary";
import {InputWithNavigation} from "./ScheduleForm";

export const enum ScheduleFormInputTypes {
  PICKUP = "pickup",
  DELIVERY = "delivery",
}

interface IScheduleFormInput {
  type: ScheduleFormInputTypes;
  assets: Partial<InputWithNavigation>;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  action?: () => void;
}

export const ScheduleFormPickupDeliveryInput: FC<IScheduleFormInput> = ({
  type = ScheduleFormInputTypes.PICKUP,
  assets,
  onChange,
  action,
}) => {
  const [isWrapperActive, setWrapperActive] = useState<boolean>(false);
  const {
    isSkeleton,
    current,
    isOnDemandDelivery,
    isScheduleWhenReady,
    price,
    duration,
    timing,
    isAutoSchedule,
    windowConflicts,
    maxStops,
    shiftName,
    shiftColor,
  } = assets;

  const isAvailableToEdit =
    typeof action === "function" &&
    !(isAutoSchedule && type === ScheduleFormInputTypes.DELIVERY);

  const isOperatorSession = checkOOBO();
  const {data: nearStoresData} = useSelector(orderSelectors.getNearStoresData);
  const {onDemandIsAvailable, onDemandProviderDetails: nashData} = nearStoresData;

  return (
    <A11yButton
      className={`pickup-delivery-input-wrapper${
        isAvailableToEdit ? "" : " disabled"
      } outline-distant`}
      data-active={isWrapperActive}
      onAction={() => (isAvailableToEdit ? action?.() : void 0)}
      disabled={!isAvailableToEdit}
      data-testid={
        type === ScheduleFormInputTypes.PICKUP
          ? PICKUP_WINDOW_BUTTON
          : DELIVERY_WINDOW_BUTTON
      }
    >
      <div className="column">
        <h5>{type} Details</h5>
        <div className="pickup-delivery-details">
          {isSkeleton && <span className="enlarged"></span>}
          {isScheduleWhenReady && !isSkeleton ? (
            <>
              <span className="enlarged">
                {type === ScheduleFormInputTypes.DELIVERY ? (
                  DICTIONARY.SCHEDULE_LATER_HEADER
                ) : (
                  <strong>{DICTIONARY.UNSCHEDULED_HEADER}</strong>
                )}
              </span>
              {type === ScheduleFormInputTypes.DELIVERY
                ? DICTIONARY.SCHEDULE_LATER_TEXT(duration as string)
                : null}
            </>
          ) : null}
          {!isScheduleWhenReady && !isSkeleton ? (
            <>
              {timing?.date as string}
              <span className="enlarged">{timing?.interval as string}</span>
              {isOperatorSession && shiftName && shiftColor ? (
                <DeliveryShiftLabel
                  shiftName={shiftName}
                  shiftColor={isOnDemandDelivery ? null : shiftColor}
                />
              ) : null}
            </>
          ) : null}
        </div>
      </div>
      <div className="column">
        {isAvailableToEdit && (
          <span
            className="additional-action-handler"
            onPointerEnter={() => setWrapperActive(true)}
            onPointerLeave={() => setWrapperActive(false)}
          >
            <PencilIcon />
          </span>
        )}
        <span className="delivery-service-info">
          {!isScheduleWhenReady && !isSkeleton && onDemandIsAvailable ? (
            <>
              {isOnDemandDelivery ? (
                <div className="delivery-service-provider">
                  <span>{nashData?.providerName}</span>
                  <img
                    width={20}
                    height={20}
                    src={nashData?.providerLogo}
                    alt="Delivery provider logo"
                  />
                </div>
              ) : (
                <span className="delivery-service-provider">
                  {SCHEDULE_TABS.ourDrivers}
                </span>
              )}
            </>
          ) : (
            <></>
          )}

          {!isSkeleton ? (!isScheduleWhenReady ? price : " ") : null}
          {windowConflicts?.length || maxStops === 0 ? <DeliveryConflictLabel /> : null}
        </span>
      </div>
      <input
        type="hidden"
        name={type}
        onChange={onChange}
        value={(current as string) || ""}
      />
    </A11yButton>
  );
};
