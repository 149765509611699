import {useCallback, useEffect, useMemo, useState} from "react";
import {DeviceStatuses, TopOffModes} from "features/order/self-serve/types";
import ChooseAmount from "features/payment/availableCredit/components/chooseAmount";
import type {IAddCredit} from "features/payment/availableCredit/types";
import {useFlags, useLDClient} from "launchdarkly-react-client-sdk";
import {Channel} from "pusher-js";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {Layout} from "components/common/layouts/Layout";
import {AdditionalPaymentDueDrawer} from "components/payment/AdditionalPaymentDueDrawer";
import {
  DeviceStatusPusherResponse,
  DeviceTransactionPusherResponse,
} from "components/self-order/types";
import usePusher from "hooks/usePusher";
import useToggle from "hooks/useToggle";
import {useAppSelector} from "state/redux/hooks";
import {getBusinessName} from "state/redux/slices/business/selectors";
import {getQueryString} from "utils/common";
import {checkIfMachineIsGPIO} from "utils/machineUtil";
import {formatCurrency} from "utils/payment";
import type {UseAddCreditsPayload} from "api/queries/useAddCredits";
import {useAddCredits} from "api/queries/useAddCredits";
import {useCustomerInfo} from "api/queries/useCustomerInfo";
import {useGetTurnDetails} from "api/queries/useGetTurnDetails";
import {useProcessAdditionalMachineVend} from "api/queries/useProcessAdditionalMachineVend";
import {useRealtimeStatus} from "api/queries/useRealtimeStatus";
import {PUSHER_EVENTS} from "constants/constants";
import {SELF_ORDER_INFO_PATH, SELF_SERVE_INFO_PATH} from "constants/paths";
import {SmallWarningIcon, IconDollar, PlusIcon} from "assets/images";
import {ICustomer, IPaymentMethod, IAddress} from "types/customer";
import {MachineManufacturers, MachineTypes, SelfServiceStatuses} from "../../constants";
import {ElementNotFound, Loader} from "../common";
import {LifeCycleTimeline} from "../common/life-cycle-timeline";
import {Footer} from "./Footer";
import {SelfOrderHeader} from "./Header";
import SelfOrderStyles from "./SelfOrder.module.scss";
import {configureLifeCycleData} from "./SelfOrder.service";
import {SelfOrderDetails} from "./SelfOrderDetails";
import {StatusDescription} from "./StatusDescription";
import {StatusImage} from "./StatusImage";

interface SelfOrderUrlParams {
  id: string;
}

interface SelfOrderProps {
  id?: number | null;
  isModal?: boolean;
  balanceInDollars?: number | null;
  customerInfo?: Pick<ICustomer, "id" | "paymentMethods" | "addresses"> | null;
  refetchBalance: UseAddCreditsPayload["refetch"];
  openSelfOrder?: (turnId: number | null) => void;
  machineId?: number;
}

const SelfOrder: React.FC<SelfOrderProps> = ({
  isModal,
  balanceInDollars,
  refetchBalance,
  openSelfOrder,
  machineId,
  ...props
}) => {
  const flags = useFlags();
  const history = useHistory();
  const ldClient = useLDClient();
  const pusherClient = usePusher();
  const params = useParams<SelfOrderUrlParams>();
  const {search, pathname} = useLocation();
  const businessName = useAppSelector(getBusinessName);
  const {machineId: queryParamsMachineId, businessId: queryParamsBusinessId} =
    getQueryString(search);
  const [customerInfo, setCustomerInfo] = useState<
    ICustomer | Pick<ICustomer, "id" | "paymentMethods" | "addresses"> | null
  >(null);
  const [turnId, setTurnId] = useState<number | null>(null);
  const [deviceSettings, setDeviceSettings] = useState<DeviceStatusPusherResponse | null>(
    null
  );
  const {isOpen: showAddTimeModal, toggle: toggleShowAddTimeModal} = useToggle();
  const [showAdditionalPaymentDrawer, setShowAdditionalPaymentDrawer] = useState(false);
  const [showAddFundsModal, toggleShowAddFundsModal] = useState(false);

  // When the user presses start on /self-serve page he is redirected to this page,
  // but the turn hasn't actually been created yet, ID is null, the turn will be created
  // only when device send transaction event and then BE will send "turn-created" event
  // after that user will be redirected to the /self-order/:id page.
  // We subscribe on the "turn-created" event in this component
  const isWaitingForTurnCreation = turnId === null;

  const {
    data: turnDetails,
    isFetching: isFetchingTurnDetails,
    refetch: fetchTurnDetails,
  } = useGetTurnDetails({turnId, enabled: false});

  const {
    data: fetchedCustomerInfo,
    isFetching: isFetchingCustomerInfo,
    refetch: fetchCustomerInformation,
  } = useCustomerInfo({storeId: turnDetails?.store.id, enabled: false});

  const {data: realtimeStatusData, refetch: fetchRealtimeStatus} = useRealtimeStatus({
    machineId: turnDetails?.machine.id || Number(queryParamsMachineId) || machineId,
    enabled: false,
  });

  const {isLoading: isAddCreditsLoading, mutate: addCredits} = useAddCredits({
    refetch: refetchBalance,
  });

  const {isLoading: isProcessingAdditionalVend, mutate: processAdditionalVend} =
    useProcessAdditionalMachineVend({refetch: fetchCustomerInformation});

  // fetch turn details when it's needed
  useEffect(() => {
    if (turnId && !isWaitingForTurnCreation) {
      fetchTurnDetails();
    }
  }, [fetchTurnDetails, turnId, isWaitingForTurnCreation]);

  useEffect(() => {
    if (isModal) {
      setTurnId(props.id!);
      setCustomerInfo(props.customerInfo!);
    } else {
      setTurnId(params.id !== "null" ? Number(params.id) : null);
    }
  }, [props.id, props.customerInfo, isModal, params.id]);

  // fetch customer info when it's needed
  useEffect(() => {
    if (!isModal && turnDetails?.store.id) {
      fetchCustomerInformation();
    }
  }, [turnDetails?.store.id, isModal, fetchCustomerInformation]);

  // called on customer info fetch
  useEffect(() => {
    if (!isModal && fetchedCustomerInfo) {
      setCustomerInfo(fetchedCustomerInfo.customer);
    }
  }, [isModal, fetchedCustomerInfo]);

  // request realtime status when it's needed
  useEffect(() => {
    if (turnDetails?.machine.id || Number(queryParamsMachineId) || machineId) {
      fetchRealtimeStatus();
    }
  }, [turnDetails?.machine.id, queryParamsMachineId, machineId, fetchRealtimeStatus]);

  const isGPIOMachine = checkIfMachineIsGPIO(
    turnDetails?.machine.deviceMode,
    turnDetails?.machine.isDumbDumb
  );

  const turnStatusToDisplay = useMemo(() => {
    if (isWaitingForTurnCreation) {
      return SelfServiceStatuses.ENABLED;
    }

    if (deviceSettings) {
      return (
        deviceSettings?.activeTurn?.status ||
        deviceSettings?.lastCompletedTurn?.status ||
        SelfServiceStatuses.COMPLETED
      );
    }

    return turnDetails?.status;
  }, [deviceSettings, isWaitingForTurnCreation, turnDetails?.status]);

  const manufacturersThatAllowExplicitTopOff = [
    MachineManufacturers.ACA,
    MachineManufacturers.DEXTER,
  ];

  const availableCredit =
    balanceInDollars ??
    (customerInfo as ICustomer)?.businessCustomer?.availableCredits ??
    (customerInfo as ICustomer)?.availableCredits;

  const isAbleToAddTime = () => {
    if (
      flags?.addTimeInLiveLink &&
      turnDetails &&
      [DeviceStatuses.IN_USE, DeviceStatuses.COMPLETED].includes(
        deviceSettings?.status as DeviceStatuses
      )
    ) {
      return manufacturersThatAllowExplicitTopOff.includes(
        turnDetails?.machine?.manufacturer as MachineManufacturers
      ) || isGPIOMachine
        ? turnDetails?.machine?.type === MachineTypes.DRYER &&
            turnDetails?.topOffData?.topOffMode &&
            turnDetails?.topOffData?.topOffMode !== TopOffModes.DISABLE
        : turnDetails?.machine?.type === MachineTypes.DRYER &&
            Number(deviceSettings?.vendRemaining) > 0 &&
            availableCredit >= Number((deviceSettings?.vendRemaining ?? 0) / 100);
    }

    return false;
  };

  const handlePusherDeviceStatus = useCallback(
    (pusherData: DeviceStatusPusherResponse) => {
      const turnToCheck =
        pusherData.status === DeviceStatuses.COMPLETED
          ? pusherData.lastCompletedTurn
          : pusherData.activeTurn;
      const shouldUpdateDeviceSettings =
        (turnId && turnToCheck?.id === turnId) ||
        pusherData.status === DeviceStatuses.ONLINE;

      if (shouldUpdateDeviceSettings) {
        setDeviceSettings(pusherData);
        setShowAdditionalPaymentDrawer(!!pusherData.vendRemaining);
      }

      // redirect to /self-order/:turnId
      if (pathname === `${SELF_ORDER_INFO_PATH}/${null}` && turnToCheck?.id) {
        if (isModal && openSelfOrder) {
          openSelfOrder(turnToCheck.id);
        } else {
          history.push(`${SELF_ORDER_INFO_PATH}/${turnToCheck.id}`);
        }
      }
    },
    [isModal, turnId, pathname, openSelfOrder, history]
  );

  const handlePusherDeviceTransaction = useCallback(
    (pusherData: DeviceTransactionPusherResponse) => {
      if (isModal && openSelfOrder) {
        openSelfOrder(pusherData.turnId);
      } else {
        const {centsCustomerId} = getQueryString(search);
        const shouldRedirect =
          Number(centsCustomerId) === pusherData.centsCustomerId &&
          pathname.includes(SELF_ORDER_INFO_PATH);
        if (shouldRedirect) {
          history.push(`${SELF_ORDER_INFO_PATH}/${pusherData.turnId}`);
        }
      }
    },
    [isModal, openSelfOrder, search, pathname, history]
  );

  useEffect(() => {
    let channel: Channel | null = null;

    const selectedMachineId =
      turnDetails?.machine.id || Number(queryParamsMachineId) || machineId;
    if (pusherClient && selectedMachineId) {
      channel = pusherClient.subscribe(`private-machine-${selectedMachineId}`);

      channel.bind("pusher:subscription_error", (error: any) => {
        console.warn("Could not subscribe to", channel?.name, error);
      });

      channel.bind(PUSHER_EVENTS.DEVICE_STATUS_UPDATED, handlePusherDeviceStatus);

      channel.bind(PUSHER_EVENTS.TURN_CREATED, handlePusherDeviceTransaction);
    }

    return () => {
      if (channel && pusherClient) {
        channel.unbind(PUSHER_EVENTS.DEVICE_STATUS_UPDATED);
        pusherClient.unsubscribe(channel?.name);
        channel = null;
      }
    };
  }, [
    pusherClient,
    handlePusherDeviceStatus,
    handlePusherDeviceTransaction,
    turnDetails?.machine.id,
    queryParamsMachineId,
    machineId,
  ]);

  useEffect(() => {
    const businessId = turnDetails?.business?.id;

    if (businessId && ldClient) {
      const user = {
        key: businessId.toString(),
        custom: {
          businessId: businessId,
        },
      };

      ldClient.identify(user, undefined);
    }
  }, [ldClient, turnDetails?.business?.id]);

  const startAgain = async () => {
    history.push(`${SELF_SERVE_INFO_PATH}/${turnDetails?.machine.qrCode?.hash}`);
  };

  const isLoading =
    isFetchingTurnDetails ||
    isFetchingCustomerInfo ||
    isAddCreditsLoading ||
    isProcessingAdditionalVend;
  const formattedRealtimeStatusData = realtimeStatusData
    ? {
        ...realtimeStatusData,
        name: realtimeStatusData.machineName,
        prefix: realtimeStatusData.machineType[0],
      }
    : null;

  const turnStartedAt =
    turnDetails?.startedAt ??
    deviceSettings?.activeTurn?.startedAt ??
    deviceSettings?.lastCompletedTurn?.startedAt;

  const onSubmitForProcessingAdditionalVend = async () => {
    const storeCustomerId = fetchedCustomerInfo?.customer?.storePreferences[0]
      ?.id as number;
    const body = {
      machineId: turnDetails?.machine.id as number,
      turnId: turnDetails?.id as number,
      businessId: turnDetails?.business.id as number,
      additionalVendAmount: Number(deviceSettings?.vendRemaining),
      storeCustomerId,
    };
    setShowAdditionalPaymentDrawer(false);
    processAdditionalVend(body);
  };

  const onSendAmount = ({credits, paymentMethodToken, paymentMethodId}: IAddCredit) => {
    addCredits({
      credits,
      paymentMethodToken,
      paymentMethodId,
      businessId: turnDetails?.business.id as number,
      storeId: turnDetails?.store.id as number,
    });
  };

  const handleCreditModalSelection = () => {
    toggleShowAddFundsModal(!showAddFundsModal);
    setShowAdditionalPaymentDrawer(!showAdditionalPaymentDrawer);
  };

  const shouldDisableAdditionalPaymentButton = () => {
    if (!deviceSettings?.vendRemaining) return true;

    if (availableCredit < Number(deviceSettings?.vendRemaining / 100)) return true;

    return false;
  };

  const renderAdditionalPaymentDueDrawer = () => {
    return (
      <AdditionalPaymentDueDrawer
        anchor={"bottom"}
        open={showAdditionalPaymentDrawer}
        onSubmit={onSubmitForProcessingAdditionalVend}
        drawerHeader={"Additional payment required"}
        drawerText={`You have updated your ${
          turnDetails?.machine?.type === MachineTypes.DRYER ? "dry" : "wash"
        } settings. To start, please pay the new balance.`}
        icon={<img alt={"warning icon for required payment"} src={SmallWarningIcon} />}
        buttonText={`Pay ${formatCurrency(deviceSettings?.vendRemaining)} + Enable Start`}
        disabled={shouldDisableAdditionalPaymentButton()}
      >
        <div
          className={SelfOrderStyles.additionalPaymentContainer}
          onClick={handleCreditModalSelection}
        >
          <div className={SelfOrderStyles.availableCreditPaymentRow}>
            <div className={SelfOrderStyles.additionalPaymentAvailableAmountContainer}>
              <img src={IconDollar} alt="dollar-sign-icon" />
              <div className={SelfOrderStyles.availableCreditPaymentColumnItem}>
                <h2 className={SelfOrderStyles.availableCreditHeaderText}>
                  Available Credit
                </h2>
                <p className={SelfOrderStyles.availableCreditFooterText}>
                  {`$${availableCredit?.toFixed(2)}`}
                </p>
              </div>
            </div>
            <img src={PlusIcon} alt="plus-icon" />
          </div>
        </div>
      </AdditionalPaymentDueDrawer>
    );
  };

  return isLoading ? (
    <Loader />
  ) : (
    <>
      {(isWaitingForTurnCreation ? formattedRealtimeStatusData : turnDetails) ? (
        <Layout
          businessSettings={{
            businessId:
              turnDetails?.business?.id ?? (queryParamsBusinessId as unknown as number),
          }}
          headerName={businessName || undefined}
          isSelfServeHeader
          withoutHeader={isModal}
        >
          <div style={styles.container}>
            <SelfOrderHeader
              ableToAddTime={isAbleToAddTime()}
              startAgain={startAgain}
              createdAt={turnDetails?.createdAt}
              machine={
                isWaitingForTurnCreation
                  ? formattedRealtimeStatusData
                  : turnDetails?.machine
              }
              topOffData={turnDetails?.topOffData}
              customer={customerInfo}
              totalTurnTimeInMinutes={turnDetails?.totalTurnTime}
              manufacturer={turnDetails?.machine?.manufacturer}
              storeId={turnDetails?.store.id}
              turnId={turnDetails?.id}
              status={turnStatusToDisplay}
              isGPIOMachine={isGPIOMachine}
              showAddTimeModal={showAddTimeModal}
              toggleShowAddTimeModal={toggleShowAddTimeModal}
              balanceInDollars={
                balanceInDollars ??
                (customerInfo as ICustomer)?.businessCustomer?.availableCredits ??
                (customerInfo as ICustomer)?.availableCredits
              }
              refetchBalance={refetchBalance}
              deviceSettings={deviceSettings}
            />
            {!turnDetails?.machine.isDumbDumb && (
              <LifeCycleTimeline data={configureLifeCycleData(turnStatusToDisplay, 3)} />
            )}
            <StatusDescription
              startedAt={turnStartedAt}
              status={turnStatusToDisplay}
              machine={turnDetails?.machine}
              isWaitingForTurnCreation={isWaitingForTurnCreation}
            />
            <StatusImage
              isDumbDumb={turnDetails?.machine.isDumbDumb}
              status={turnStatusToDisplay}
              isWaitingForTurnCreation={isWaitingForTurnCreation}
            />
            {!showAddTimeModal &&
              !isWaitingForTurnCreation &&
              turnDetails &&
              !showAddFundsModal && (
                <SelfOrderDetails
                  order={turnDetails?.order}
                  customer={turnDetails?.centsCustomer}
                  turnCodeWithPrefix={turnDetails?.turnCodeWithPrefix}
                  machine={turnDetails?.machine}
                />
              )}
            <Footer />
          </div>
          {flags?.additionalVendInLiveLink && (
            <>
              {showAdditionalPaymentDrawer && renderAdditionalPaymentDueDrawer()}
              <ChooseAmount
                customer={{
                  id: (props.customerInfo?.id ??
                    fetchedCustomerInfo?.customer?.id) as number,
                  paymentMethods:
                    props.customerInfo?.paymentMethods ??
                    (fetchedCustomerInfo?.customer?.paymentMethods as IPaymentMethod[]),
                  addresses:
                    props.customerInfo?.addresses ??
                    (fetchedCustomerInfo?.customer?.addresses as IAddress[]),
                }}
                showModal={showAddFundsModal}
                toggleShowModal={handleCreditModalSelection}
                sendAmount={onSendAmount}
              />
            </>
          )}
        </Layout>
      ) : (
        <ElementNotFound />
      )}
    </>
  );
};

export default SelfOrder;

const styles = {
  container: {
    margin: "16px 24px",
  },
};
