import {useCallback} from "react";
import TagManager from "@sooro-io/react-gtm-module";
import {useIsServiceFirstFlow} from "hooks/orderBuilder/useIsServiceFirstFlow";
import {useAppSelector} from "state/redux/hooks";
import {
  getBusinessId,
  getBusinessName,
  getStoreId,
  getStoreName,
} from "state/redux/slices/business/selectors";
import {getCentsCustomer} from "state/redux/slices/customer/selectors/credentialsSelector";
import {checkOOBO} from "utils/ooboHelpers";
import {CentsCustomer} from "../api/customer/fetchCentsCustomer";
import {submitFsEvent} from "./fullstory/useFullStoryEvent";

export interface IEventProperties {
  eventName: string;
  businessId: number | null;
  businessName: string | null;
  storeId?: number;
  storeName?: string;
  centsCustomer?: CentsCustomer | null;
  isOperatorSession: boolean;
  isRWGFlow: boolean;
}

export const useSubmitEvent = (sendToFs = true, sendToGtm = true) => {
  const businessId = useAppSelector(getBusinessId);
  const businessName = useAppSelector(getBusinessName);
  const storeId = useAppSelector(getStoreId);
  const storeName = useAppSelector(getStoreName);
  const centsCustomer = useAppSelector(getCentsCustomer);
  const isOperatorSession = checkOOBO();
  const isRWGFlow = useIsServiceFirstFlow();

  const submitEvent = useCallback(
    (eventName: string, additionalData: Record<string, any> = {}) => {
      if (sendToFs) {
        submitFsEvent({
          eventName,
          eventProperties: {
            eventName,
            businessId,
            businessName,
            storeId,
            storeName,
            centsCustomer,
            isOperatorSession,
            isRWGFlow,
          },
          additionalData,
        });
      }

      if (sendToGtm) {
        TagManager.dataLayer({
          dataLayer: {
            event: eventName,
            businessId,
            businessName,
            storeId,
            storeName,
            centsCustomer,
            isOperatorSession,
            isRWGFlow,
            ...additionalData,
          },
        });
      }
    },
    [
      businessId,
      businessName,
      centsCustomer,
      isOperatorSession,
      isRWGFlow,
      storeId,
      storeName,
    ]
  );

  return {submitEvent};
};
