import {Dispatch, RefObject, SetStateAction, useState} from "react";
import * as Sentry from "@sentry/browser";
import {isEmpty} from "lodash";
import {useHistory, useRouteMatch} from "react-router-dom";
import {useEstimateAndSetDelivery} from "components/newOrder/hooks/nash/useEstimateAndSetDelivery";
import {URLS} from "components/newOrder/routes/schedule/SchedulingScreen";
import {useSubmitEvent} from "hooks/useSumbitEvent";
import {useAppDispatch, useAppSelector} from "state/redux/hooks";
import {orderActions, orderSelectors} from "state/redux/slices/order";
import {ITransportationDeliveryInfo} from "state/redux/slices/order/types/orderBuilder";
import {validateNashDeliveryWindows} from "utils/newOrder/nash/validateNashDeliveryWindows";
import {
  checkBusinessAssetsInSessionStorage,
  updateCurrentBusinessAssets,
} from "utils/ooboHelpers";
import {
  formatOrderDelivery,
  getTotalDeliveryCost,
} from "utils/schedule/formatOrderDelivery";
import {OnDemandEstimateResponse} from "api/nash/getDeliveryEstimate";
import {DELIVERY_WINDOWS_ARE_SUBMITTED} from "constants/fullStory/events/delivery";
import {SUBSCRIPTION_IS_SUBMITTED} from "constants/fullStory/events/subscription";
import {OOBO_CONFLICTS_TYPES, DELIVERY_PROVIDERS} from "constants/order";
import {type ISubscription} from "types/customer";
import {useDeliveryConflicts} from "./useDeliveryConflicts";

type UseDeliveryWindowsSubmit = (data: {
  scheduleFormRef: RefObject<HTMLFormElement>;
  validateAndSetSubscription: (
    interval: string | number | null
  ) => Partial<ISubscription> | void;
  setIsNextButtonDisabled: Dispatch<SetStateAction<boolean>>;
  setIsShowingConflicts: Dispatch<SetStateAction<boolean>>;
}) => {
  isNashEstimating: boolean;
  onScheduleFormSubmit: () => Promise<void>;
  submitDeliveryWindows: () => Promise<void>;
};

export const useDeliveryWindowsSubmit: UseDeliveryWindowsSubmit = ({
  scheduleFormRef,
  validateAndSetSubscription,
  setIsNextButtonDisabled,
  setIsShowingConflicts,
}) => {
  const {submitEvent} = useSubmitEvent();
  const [isNashEstimating, setIsNashEstimating] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const {url} = useRouteMatch();
  const history = useHistory();
  const state = useAppSelector(orderSelectors.getOnlineOrderData);
  const {
    schedule: {pickup, returnInfo},
    currentCustomerAddress,
    nearStoresData: {data: availableStores},
  } = state;
  const isAutoSchedule = Boolean(availableStores?.autoScheduleReturnEnabled);

  const customerAddressId = currentCustomerAddress?.id;
  const {estimateAndSetDelivery} = useEstimateAndSetDelivery({
    customerAddressId: Number(customerAddressId),
  });
  const {getDeliveryConflicts} = useDeliveryConflicts();
  const timeZone = availableStores?.deliveryDays[0]?.timeZone;
  const zipCode = availableStores?.deliveryDays[0]?.customerZipCode;

  const onScheduleFormSubmit = async () => {
    const {conflictsTypes} = getDeliveryConflicts();
    if (conflictsTypes.length) {
      setIsShowingConflicts(true);
      dispatch(
        orderActions.setOOBOConflicts(
          conflictsTypes.map((type) => OOBO_CONFLICTS_TYPES[type])
        )
      );
    } else {
      await submitDeliveryWindows();
    }
  };

  const submitDeliveryWindows = async (): Promise<void> => {
    const data = new FormData(scheduleFormRef.current as HTMLFormElement);
    const formData = Object.fromEntries(data.entries());

    const subscription = validateAndSetSubscription(formData.interval as string);

    if (returnInfo && !Object.keys(returnInfo).length) {
      dispatch(orderActions.setReturnInfo(null));
    }

    let isOnDemandEstimateSuccessful = true;
    let pickupEstimate: OnDemandEstimateResponse | null = null;
    let returnEstimate: OnDemandEstimateResponse | null = null;

    const deliveryEstimateStarted = new Date().valueOf();
    if (pickup) {
      try {
        setIsNashEstimating(true);
        const {
          isSuccessful,
          pickupEstimate: updatedPickupEstimate,
          returnEstimate: updatedReturnEstimate,
        } = await validateNashDeliveryWindows({
          pickup,
          returnInfo,
          dispatch,
          estimateAndSetDelivery,
          timeZone,
          zipCode,
          isAutoSchedule,
        });

        isOnDemandEstimateSuccessful = isSuccessful;
        pickupEstimate = updatedPickupEstimate;
        returnEstimate = updatedReturnEstimate;
      } finally {
        setIsNashEstimating(false);
      }
    }

    setIsNextButtonDisabled(true);
    const currentCentsCustomerCredentials =
      state.initialOrderData.data.centsCustomerCredentials;
    const isAssetsExist = checkBusinessAssetsInSessionStorage();
    isAssetsExist &&
      updateCurrentBusinessAssets({
        storeName:
          state.nearStoresData.data.onDemandDeliveryStore?.storeName ||
          state.nearStoresData.data.ownDeliveryStore?.storeName,
        customerName: `${currentCentsCustomerCredentials.firstName} ${currentCentsCustomerCredentials.lastName}`,
        customerPhone: currentCentsCustomerCredentials.phoneNumber,
      });

    // @ts-expect-error because of untyped formatOrderDelivery
    const orderDelivery = formatOrderDelivery({
      schedule: {
        ...state.schedule,
        pickup: {...pickup, estimate: pickupEstimate},
        returnInfo: returnInfo?.key ? {...returnInfo, estimate: returnEstimate} : null,
      },
      pickupCourierTip: 0,
      returnCourierTip: 0,
      doorDashSubsidy:
        state.nearStoresData.data.onDemandDeliveryStore?.subsidyInCents || 0,
      doorDashFee:
        state.nearStoresData.data.onDemandDeliveryStore?.deliveryFeeInCents || 0,
      pickupThirdPartyDeliveryId: pickupEstimate?.estimateId || null,
    });
    // @ts-check

    dispatch(
      orderActions.setTransportationPickupDelivery(
        orderDelivery.pickup as ITransportationDeliveryInfo
      )
    );
    dispatch(
      orderActions.setTransportationReturnDelivery(
        orderDelivery.delivery as ITransportationDeliveryInfo
      )
    );

    const deliveryEstimateFinished = new Date().valueOf();

    try {
      const onDemandSubsidy = availableStores?.onDemandDeliveryStore?.subsidyInCents || 0;
      submitEvent(DELIVERY_WINDOWS_ARE_SUBMITTED, {
        pickup: {
          fee: getTotalDeliveryCost(pickup, onDemandSubsidy) * 100,
          type: pickup?.type,
          onDemandProviderName:
            pickup?.type === DELIVERY_PROVIDERS.onDemand
              ? availableStores?.onDemandProviderDetails?.providerName
              : null,
        },
        delivery: returnInfo?.key
          ? {
              fee: getTotalDeliveryCost(returnInfo, onDemandSubsidy) * 100,
              type: returnInfo?.type,
              onDemandProviderName:
                returnInfo?.type === DELIVERY_PROVIDERS.onDemand
                  ? availableStores?.onDemandProviderDetails?.providerName
                  : null,
            }
          : null,
        ownDeliveryIsAvailable: availableStores?.ownDeliveryIsAvailable,
        onDemandIsAvailable: availableStores?.onDemandIsAvailable,
        timeToSubmit: deliveryEstimateFinished - deliveryEstimateStarted,
      });
      if (!isEmpty(subscription)) {
        submitEvent(SUBSCRIPTION_IS_SUBMITTED, {subscriptionDetails: subscription});
      }
    } catch (error) {
      Sentry.captureException(error);
    }

    if (formData.interval && formData.pickup && isOnDemandEstimateSuccessful) {
      history.push(String(url).replace(URLS.CURRENT, URLS.NEXT));
    }
  };

  return {onScheduleFormSubmit, isNashEstimating, submitDeliveryWindows};
};
