import {toast} from "react-toastify";
import {Box, Flex, Image, Text} from "rebass/styled-components";
import {Loader, ToastError} from "components/common";
import {useAppDispatch, useAppSelector} from "state/redux/hooks";
import {EN_LOCALE} from "locales/en";
import {IconDollar, PlusIcon} from "assets/images";
import {FetchingStatus} from "types/common";
import {ICustomer} from "types/customer";
import {availableCreditSelectors, availableCreditThunks} from "../../redux";
import {IAddCredit} from "../../types";
import ChooseAmount from "../chooseAmount";
import styles from "./styles";

interface IAvailableCreditWrapperProps {
  headerBlockShow?: boolean;
  storeId: number | null;
  machine?: {
    id: number | null;
    name: string;
  } | null;
  customer: Pick<ICustomer, "id" | "paymentMethods" | "addresses"> | null;
  currAvailableCredit: number;
  setCurrAvailableCredit: (data: number) => void;
  showModal: boolean;
  toggleShowModal: (status: boolean) => void;
}

const AvailableCreditWrapper = ({
  headerBlockShow = true,
  customer,
  storeId,
  currAvailableCredit,
  setCurrAvailableCredit,
  showModal,
  toggleShowModal,
  machine,
}: IAvailableCreditWrapperProps) => {
  const funds = useAppSelector(availableCreditSelectors.getFunds);
  const dispatch = useAppDispatch();

  const sendAddCreditDispatch = (data: IAddCredit) =>
    dispatch(availableCreditThunks.addCreditThunk(data));

  const sendAmount = async (data: IAddCredit) => {
    try {
      const newAvailableCredit = await sendAddCreditDispatch(data).unwrap();

      setCurrAvailableCredit(newAvailableCredit);
      toast.success("Success! You added new amount!");
    } catch (error) {
      const err = error as {text: string};
      toast.error(<ToastError message={err.text} />);
    }
  };

  return (
    <Box>
      {headerBlockShow && (
        <Flex>
          <Text sx={styles.sectionHeader}>Payment</Text>
        </Flex>
      )}

      <Flex
        {...styles.section.link.wrapper}
        onClick={() => {
          toggleShowModal(!showModal);
        }}
      >
        <Box sx={styles.section.link.iconWrapper}>
          <Image src={IconDollar} alt={EN_LOCALE.label.money} />
        </Box>
        <Flex sx={styles.section.link.dataWrapper}>
          <Box sx={styles.section.link.data}>
            Available Credit
            <Text
              {...styles.section.link.dataSubText}
              color={currAvailableCredit ? "TEXT_GREY" : "TEXT_RED"}
            >
              {`$${currAvailableCredit?.toFixed(2)}`}
            </Text>
          </Box>
          <Image
            src={PlusIcon}
            sx={styles.section.link.rightChevron}
            alt={EN_LOCALE.label.chevron}
          />
        </Flex>
      </Flex>
      <ChooseAmount
        storeId={storeId}
        customer={customer}
        machine={machine}
        showModal={showModal}
        toggleShowModal={toggleShowModal}
        sendAmount={sendAmount}
      />
      {funds.fetchingStatus === FetchingStatus.Pending ? <Loader /> : null}
    </Box>
  );
};

export default AvailableCreditWrapper;
