import {GeneralDeliverySettings} from "api/delivery/fetchGeneralDeliverySettings";
import {ICustomer} from "types/customer";

interface IArgs {
  customer: ICustomer;
  settings: Pick<GeneralDeliverySettings, "allowCommercialInvoicing">;
}

export const getInvoicingFlag = ({customer, settings}: IArgs): boolean => {
  if (customer.businessCustomer && settings?.allowCommercialInvoicing) {
    const businessCustomer = customer.businessCustomer;
    return businessCustomer.isCommercial && businessCustomer.isInvoicingEnabled;
  }
  return false;
};
