import {createSlice} from "@reduxjs/toolkit";
import moment from "moment";
import {toast} from "react-toastify";
import {filterDeliveryDays} from "hooks/schedule/useWindowsPerDay";
import {FETCHING_STATUS} from "constants/api";
import {FetchingStatus} from "types/common";
import {deleteAddress} from "../customer/thunks/deleteAddress";
import {patchAddress} from "../customer/thunks/patchAddress";
import {ONLINE_ORDER_REDUCER} from "./constants/general";
import initialData from "./constants/initData";
import {orderReducers} from "./reducers";
import {
  getOrderInitialData,
  getCustomerSubscriptionsList,
  getReturnWindows,
  getNearStores,
  patchCustomerCredentials,
} from "./thunks";
import {fetchDeliveryServices} from "./thunks/fetchDeliveryServices";
import {fetchDeliverySettingsThunk} from "./thunks/fetchDeliverySettingsThunk";
import {fetchOnlineOrderServicesThunk} from "./thunks/fetchOnlineOrderServices";
import {fetchStoreServicesPrices} from "./thunks/fetchStoreServicesPrices";
import {ServiceCategoryTypes} from "./types";
import {evaluatePossibleService} from "./utils/evaluatePossibleService";
import {setCategorySelection} from "./utils/setCategorySelection";

const orderSlice = createSlice({
  name: ONLINE_ORDER_REDUCER,
  initialState: initialData,
  reducers: orderReducers,
  extraReducers: (builder) => {
    builder
      .addCase(getReturnWindows.pending, (state) => {
        state.returnWindows.fetchingStatus = FETCHING_STATUS.PENDING;
      })
      .addCase(getReturnWindows.fulfilled, (state, action) => {
        state.returnWindows.fetchingStatus = FETCHING_STATUS.FULFILLED;
        state.returnWindows.data = action.payload?.data;

        // set already selected window from the list to state
        const selectedWindow = action.payload?.selectedWindow;
        const deliveryDays =
          action.payload?.data?.deliveryDays?.filter(filterDeliveryDays);
        if (selectedWindow?.deliveryWindow?.length && deliveryDays) {
          deliveryDays.forEach((possibleWindow, index) => {
            const foundedWindow = possibleWindow[
              selectedWindow.deliveryProvider === "OWN_DRIVER"
                ? "ownDelivery"
                : "onDemandDelivery"
            ].find(
              (orderDelivery) =>
                moment(orderDelivery.startTime).valueOf() ===
                Number(selectedWindow.deliveryWindow[0])
            );
            if (foundedWindow) {
              state.schedule.returnInfo = foundedWindow;
              state.schedule.returnDayIndex = index;
            }
          });
        }
      })
      .addCase(getReturnWindows.rejected, (state, action) => {
        const {error} = action;
        state.returnWindows.fetchingStatus = FETCHING_STATUS.REJECTED;
        state.returnWindows.error = {
          text: error.message,
          code: error.name,
        };
      })
      .addCase(getOrderInitialData.pending, (state) => {
        state.initialOrderData.fetchingStatus = FETCHING_STATUS.PENDING;
      })
      .addCase(getOrderInitialData.fulfilled, (state, action) => {
        state.initialOrderData.fetchingStatus = FETCHING_STATUS.FULFILLED;
        state.initialOrderData.data = action.payload;
        if (!state.currentCustomerAddress) {
          state.currentCustomerAddress = action.payload.customerAddress; // deprecated
        }

        state.orderBuilder.store.businessId = action.payload.businessId;
        if (action.payload?.storeId) {
          state.orderBuilder.store.storeId = action.payload.storeId;
          state.orderBuilder.store.storeName = action.payload.storeName;
          state.orderBuilder.store.storeAddress = action.payload.storeAddress;
        }
        if (
          action.payload?.customerAddress?.id &&
          !state.orderBuilder.addresses.addressToEstimate
        ) {
          state.orderBuilder.addresses.addressToEstimate = action.payload.customerAddress;
        }
      })
      .addCase(getOrderInitialData.rejected, (state, action) => {
        const {error} = action;
        state.initialOrderData.fetchingStatus = FETCHING_STATUS.REJECTED;
        state.initialOrderData.error = {
          text: error.message,
          code: error.name,
        };
      })
      .addCase(patchAddress.fulfilled, (state, action) => {
        state.drawersStates.isAddressDetailsDrawerOpened = false;

        // if address was updated after store estimation
        if (
          state.orderBuilder.addresses.addressToEstimate?.id ===
          action.payload.centsCustomerAddress.id
        ) {
          state.orderBuilder.addresses.addressToEstimate =
            action.payload.centsCustomerAddress;
        }

        // deprecated
        state.initialOrderData.data.savedCustomerAddresses =
          action.payload.allCustomerAddresses;

        // deprecated
        if (action.payload?.isNew) {
          state.currentCustomerAddress = {
            ...action.payload.centsCustomerAddress,
            isNew: action.payload.isNew,
            lat: action.payload.lat,
            lng: action.payload.lng,
          };
        } else {
          state.currentCustomerAddress = action.payload.centsCustomerAddress;
        }
      })
      .addCase(deleteAddress.fulfilled, (state, action) => {
        // deprecated
        state.initialOrderData.data.savedCustomerAddresses =
          action.payload.allCustomerAddresses;
      })
      .addCase(getCustomerSubscriptionsList.pending, (state) => {
        state.initialOrderData.fetchingStatus = FETCHING_STATUS.PENDING;
      })
      .addCase(getCustomerSubscriptionsList.fulfilled, (state, action) => {
        state.initialOrderData.data.subscriptions = action.payload;
        state.initialOrderData.fetchingStatus = FETCHING_STATUS.FULFILLED;
      })
      .addCase(getCustomerSubscriptionsList.rejected, (state, action) => {
        const {error} = action;
        state.initialOrderData.fetchingStatus = FETCHING_STATUS.REJECTED;
        state.initialOrderData.error = {
          text: error.message,
          code: error.name,
        };
      })
      .addCase(getNearStores.pending, (state, action) => {
        const saveSelectedServices = action.meta.arg?.saveSelectedServices;
        state.nearStoresData.fetchingStatus = FETCHING_STATUS.PENDING;
        if (
          state.initialOrderData.data.centsCustomerCredentials?.id &&
          !saveSelectedServices
        ) {
          state.orderBuilder.services.selectedService =
            initialData.orderBuilder.services.selectedService;
          state.orderBuilder.services.selectedModifiers =
            initialData.orderBuilder.services.selectedModifiers;
        }
        state.orderBuilder.products = initialData.orderBuilder.products;
        state.orderBuilder.delivery = initialData.orderBuilder.delivery;
        state.orderBuilder.store.storeId = initialData.orderBuilder.store.storeId;
        state.orderBuilder.customMessageForCustomer =
          initialData.orderBuilder.customMessageForCustomer;
      })
      .addCase(getNearStores.fulfilled, (state, action) => {
        state.nearStoresData.fetchingStatus = FETCHING_STATUS.FULFILLED;
        state.nearStoresData.data = action.payload;

        const selectedWindow = action.payload?.selectedWindow;
        const deliveryDays = action.payload?.deliveryDays?.filter(filterDeliveryDays);
        if (selectedWindow?.deliveryWindow?.length && deliveryDays) {
          deliveryDays.forEach((possibleWindow, index) => {
            const foundedWindow = possibleWindow[
              selectedWindow.deliveryProvider === "OWN_DRIVER"
                ? "ownDelivery"
                : "onDemandDelivery"
            ].find(
              (orderDelivery) =>
                moment(orderDelivery.startTime).valueOf() ===
                  Number(selectedWindow.deliveryWindow[0]) &&
                moment(orderDelivery.endTime).valueOf() ===
                  Number(selectedWindow.deliveryWindow[1])
            );
            if (foundedWindow) {
              state.schedule.pickup = foundedWindow;
              state.schedule.pickupDayIndex = index;
            }
          });
        }

        if (!action.payload.isManageOrder) {
          state.orderBuilder.services.categories.dryCleaning.availableForDelivery =
            action.payload?.availableServices?.offerDryCleaningForDelivery;
          state.orderBuilder.store.storeId =
            action.payload?.ownDeliveryStore?.storeId ||
            action.payload?.onDemandDeliveryStore?.storeId ||
            state.orderBuilder.store.storeId;
          state.orderBuilder.store.storeName =
            action.payload?.ownDeliveryStore?.storeName ||
            action.payload?.onDemandDeliveryStore?.storeName ||
            state.orderBuilder.store.storeName;
          state.orderBuilder.store.storeAddress =
            action.payload?.storeAddress || state.orderBuilder.store.storeName;
        }
      })
      .addCase(getNearStores.rejected, (state, action) => {
        const {error} = action;
        state.nearStoresData.fetchingStatus = FETCHING_STATUS.REJECTED;
        state.nearStoresData.error = {
          text: error.message,
          code: error.name,
        };
        toast.error(error.message);
      })
      .addCase(patchCustomerCredentials.pending, (state) => {
        state.initialOrderData.fetchingStatus = FETCHING_STATUS.PENDING;
      })
      .addCase(patchCustomerCredentials.fulfilled, (state, action) => {
        state.initialOrderData.fetchingStatus = FETCHING_STATUS.FULFILLED;
        Object.assign(
          state.initialOrderData.data.centsCustomerCredentials,
          action.payload.updatedCredentials
        );
      })
      .addCase(patchCustomerCredentials.rejected, (state, action) => {
        const {text, code} = action.payload;
        state.initialOrderData.fetchingStatus = FETCHING_STATUS.REJECTED;
        state.initialOrderData.error = {
          text,
          code,
        };
      })
      .addCase(fetchStoreServicesPrices.pending, (state) => {
        state.apiRequests.servicesPrices.fetchingStatus = FETCHING_STATUS.PENDING;
      })
      .addCase(fetchStoreServicesPrices.fulfilled, (state, action) => {
        state.apiRequests.servicesPrices.fetchingStatus = FETCHING_STATUS.FULFILLED;
        state.apiRequests.servicesPrices.data = action.payload;

        state.orderBuilder.services.categories.laundry.servicesPrices =
          action.payload.laundry;
        state.orderBuilder.services.categories.dryCleaning.servicesPrices =
          action.payload.dryCleaning;
        state.orderBuilder.products.servicesPrices = action.payload.products;
      })
      .addCase(fetchStoreServicesPrices.rejected, (state, action) => {
        const {error} = action;
        state.apiRequests.servicesPrices.fetchingStatus = FETCHING_STATUS.REJECTED;
        state.apiRequests.servicesPrices.error = {
          text: error.message,
          code: error.name,
        };
      })
      .addCase(fetchDeliveryServices.pending, (state) => {
        state.apiRequests.deliveryServices.fetchingStatus = FETCHING_STATUS.PENDING;
      })
      .addCase(fetchDeliveryServices.fulfilled, (state, action) => {
        state.apiRequests.deliveryServices.fetchingStatus = FETCHING_STATUS.FULFILLED;
        state.apiRequests.deliveryServices.data = action.payload;

        state.orderBuilder.services.categories.laundry.availableLaundryServices =
          action.payload.services;
        state.orderBuilder.services.serviceMultiselectEnabled =
          action.payload.serviceMultiselectEnabled;

        if (
          state.orderBuilder.services.categories.dryCleaning.availableForDelivery ===
          false
        ) {
          setCategorySelection({
            state,
            newValue: true,
            categoryType: ServiceCategoryTypes.LAUNDRY,
          });
          if (action.payload.services?.length === 1) {
            state.orderBuilder.services.selectedService = action.payload.services[0];
          }
        }
      })
      .addCase(fetchDeliveryServices.rejected, (state, action) => {
        const {error} = action;
        state.apiRequests.deliveryServices.fetchingStatus = FETCHING_STATUS.REJECTED;
        state.apiRequests.deliveryServices.error = {
          text: error.message,
          code: error.name,
        };
      })
      .addCase(fetchDeliverySettingsThunk.pending, (state) => {
        state.apiRequests.deliverySettings.fetchingStatus = FETCHING_STATUS.PENDING;
      })
      .addCase(fetchDeliverySettingsThunk.fulfilled, (state, action) => {
        state.apiRequests.deliverySettings.fetchingStatus = FETCHING_STATUS.FULFILLED;
        state.apiRequests.deliverySettings.data = action.payload;

        state.orderBuilder.customMessageForCustomer = {
          customLiveLinkHeader:
            action.payload.generalDeliverySettings?.customLiveLinkHeader,
          customLiveLinkMessage:
            action.payload.generalDeliverySettings?.customLiveLinkMessage,
        };
        state.orderBuilder.transportation.settings.transportationPreference =
          action.payload.generalDeliverySettings.transportationPreference;
      })
      .addCase(fetchDeliverySettingsThunk.rejected, (state, action) => {
        const {error} = action;
        state.apiRequests.deliverySettings.fetchingStatus = FETCHING_STATUS.REJECTED;
        state.apiRequests.deliverySettings.error = {
          text: error.message,
          code: error.name,
        };
      })
      .addCase(fetchOnlineOrderServicesThunk.pending, (state) => {
        state.apiRequests.onlineOrderServices.fetchingStatus = FETCHING_STATUS.PENDING;
      })
      .addCase(fetchOnlineOrderServicesThunk.fulfilled, (state, action) => {
        state.apiRequests.onlineOrderServices.fetchingStatus = FETCHING_STATUS.FULFILLED;
        state.apiRequests.onlineOrderServices.data = action.payload;
        state.orderBuilder.services.serviceMultiselectEnabled =
          action.payload.serviceMultiselectEnabled;

        state.orderBuilder.services.categories.laundry.turnAround =
          action.payload.turnAround.laundryTurnAroundInHours;
        state.orderBuilder.services.categories.dryCleaning.turnAround =
          action.payload.turnAround.dryCleaningTurnAroundInHours;

        if (action.payload.isServiceFirstFlow) {
          // We need to set this value only for the first time, next the nearStores would update it
          if (
            state.orderBuilder.services.categories.dryCleaning.availableForDelivery ===
            null
          ) {
            state.orderBuilder.services.categories.dryCleaning.availableForDelivery =
              action.payload.offerDryCleaningForDelivery;
          }
          state.orderBuilder.services.categories.laundry.availableLaundryServices =
            action.payload.services;

          const isDeliveryAvailable =
            state.nearStoresData.data.ownDeliveryIsAvailable ||
            state.nearStoresData.data.onDemandIsAvailable;
          const isUserAuthorized =
            state.initialOrderData.data.centsCustomerCredentials?.id;
          const dryCleaningCategoryIsNotAvailable =
            !state.orderBuilder.services.categories.dryCleaning.availableForDelivery;
          const onlyOneLaundryServiceIsAvailable = action.payload.services?.length === 1;
          if (
            dryCleaningCategoryIsNotAvailable &&
            onlyOneLaundryServiceIsAvailable &&
            isUserAuthorized &&
            isDeliveryAvailable
          ) {
            // Select laundry service if only one is available,
            // user is authorized and store transportation is available
            const alreadyPreselectedService =
              state.orderBuilder.services.customerSelectedServices[0];
            const serviceToPreselect = {
              ...action.payload.services[0],
              customerSelectedModifiers: null,
            };
            state.orderBuilder.services.customerSelectedServices = [
              alreadyPreselectedService || serviceToPreselect,
            ];
          }

          // In case an unauthorized user has visited the RWG page,
          // we cannot know his address and whether it is served by the current Store
          // but we should correctly display services with turn around values
          // and provide an opportunity to select them.
          if (state.nearStoresData.fetchingStatus !== FetchingStatus.Fulfilled) {
            state.nearStoresData.data.ownDeliveryIsAvailable = true;
            state.nearStoresData.data.turnArounds.ownDeliveryStore =
              action.payload.turnAround;
          }

          state.orderBuilder.store.pricingTierId = action.payload.pricingTierId;

          if (
            !state.orderBuilder.services.selectedCategories
              .map(({name}) => name)
              .includes(ServiceCategoryTypes.LAUNDRY)
          ) {
            setCategorySelection({
              state,
              newValue: true,
              categoryType: ServiceCategoryTypes.LAUNDRY,
            });
          }

          if (
            state.orderBuilder.services?.possibleSelectedService?.type &&
            state.nearStoresData.fetchingStatus === FETCHING_STATUS.FULFILLED &&
            !state.orderBuilder.services.customerSelectedServices.length
          ) {
            evaluatePossibleService({state, payload: action.payload});
          }
        } else {
          state.orderBuilder.services.categories.dryCleaning.availableForDelivery =
            action.payload.offerDryCleaningForDelivery;
          state.orderBuilder.services.categories.laundry.availableLaundryServices =
            action.payload.services;
        }
      })
      .addCase(fetchOnlineOrderServicesThunk.rejected, (state, action) => {
        const {error} = action;
        state.apiRequests.onlineOrderServices.fetchingStatus = FETCHING_STATUS.REJECTED;
        state.apiRequests.onlineOrderServices.error = {
          text: error.message,
          code: error.name,
        };
      });
  },
});

export const orderActions = orderSlice.actions;
export const orderReducer = orderSlice.reducer;
