import {Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import {PrivateRoute} from "components/common/providers/PrivateRoute";
import retryLazy from "utils/retryLazy";

const OrderBusinessRoute = retryLazy(() => import("routes/order/business"));
const OrderManagerRoute = retryLazy(() => import("routes/order/id/index"));

export const OrderRoute = () => {
  const {path} = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${path}/business/:encodedBusinessLevelId`}
        component={OrderBusinessRoute}
      />
      <PrivateRoute
        path={`${path}/:serviceOrderId`}
        exact
        component={OrderManagerRoute}
      />
      <Redirect to="/" />
    </Switch>
  );
};
