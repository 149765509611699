import {createAsyncThunk} from "@reduxjs/toolkit";
import {fetchAddCredit} from "../api";
import {IAddCredit} from "../types";
import {AVAILABLE_CREDIT, ADD_FUNDS_THUNK} from "./constants/general";

export const addCreditThunk = createAsyncThunk(
  `${AVAILABLE_CREDIT}/${ADD_FUNDS_THUNK}`,
  async (payload: IAddCredit, thunkApi) => {
    try {
      const response = await fetchAddCredit(payload);
      return response.data.availableCredits;
    } catch (error: any) {
      return thunkApi.rejectWithValue({
        text: error?.response?.data?.error || error?.message || "Something went wrong",
        code: error?.response?.status || 500,
      });
    }
  }
);
