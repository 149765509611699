import React, {ComponentType} from "react";

const retryImport = async (
  importFn: () => Promise<{
    default: ComponentType<JSX.Element>;
  }>
): Promise<{default: ComponentType<JSX.Element>}> => {
  return new Promise((resolve, reject) =>
    importFn()
      .then(resolve)
      .catch(() => {
        retryImport(importFn).then(resolve, reject);
      })
  );
};

const retryLazy = (
  importFn: () => Promise<{
    default: ComponentType<JSX.Element>;
  }>
) => {
  return React.lazy(() => retryImport(importFn));
};

export default retryLazy;
