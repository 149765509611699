import {FC} from "react";
import cx from "classnames";
import {CategorySelectionCard} from "components/common/orderBuilder/CategorySelectionCard";
import {useSubmitEvent} from "hooks/useSumbitEvent";
import {
  DRY_CLEANING_CATEGORY_UNSELECTED,
  DRY_CLEANING_CATEGORY_SELECTED,
} from "constants/fullStory/events/service";
import {DryCleaningIllustration} from "assets/images";
import {DryCleaningForm} from "types/orderBuilder/services/serviceSelector";
import styles from "./dryCleaningCategory.module.scss";

export interface DryCleaningCategoryProps extends DryCleaningForm {
  dryCleaningTurnAround: number | null;
}

export const DryCleaningCategory: FC<DryCleaningCategoryProps> = ({
  isDryCleaningCategorySelected,
  dryCleaningTurnAround,
  onDryCleaningCategoryToggle,
}) => {
  const {submitEvent} = useSubmitEvent();

  const toggleCategory = () => {
    submitEvent(
      isDryCleaningCategorySelected
        ? DRY_CLEANING_CATEGORY_UNSELECTED
        : DRY_CLEANING_CATEGORY_SELECTED
    );

    onDryCleaningCategoryToggle();
  };

  return (
    <div
      className={cx(styles.categoryContainer, {
        [styles.active]: isDryCleaningCategorySelected,
      })}
    >
      <CategorySelectionCard
        imageSource={DryCleaningIllustration}
        title="Dry Cleaning"
        subtitle={dryCleaningTurnAround ? `${dryCleaningTurnAround}hr Turnaround` : ""}
        itemSelected={isDryCleaningCategorySelected}
        onClick={toggleCategory}
      >
        <span className={styles.instructions}>
          Please place garments for dry cleaning in a separate bag from your laundry.
        </span>
      </CategorySelectionCard>
    </div>
  );
};
