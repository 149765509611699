import * as FullStory from "@fullstory/browser";
import {IEventProperties} from "hooks/useSumbitEvent";

export const submitFsEvent = ({
  eventName,
  eventProperties,
  additionalData,
}: {
  eventName: string;
  eventProperties: IEventProperties;
  additionalData: Record<string, any>;
}) => {
  const {
    businessId,
    businessName,
    storeId,
    storeName,
    centsCustomer,
    isOperatorSession,
    isRWGFlow,
  } = eventProperties;

  FullStory.event(eventName, {
    businessId,
    businessName,
    storeId,
    storeName,
    centsCustomerId: centsCustomer?.id,
    customerPhoneNumber: centsCustomer?.phoneNumber,
    isOperatorSession,
    isRWGFlow,
    ...additionalData,
  });
};
