import {FetchingStatus} from "types/common";
import {IBusinessInitialData} from "../types";

const initialData: IBusinessInitialData = {
  businessId: null,
  businessName: null,
  theme: {
    boldFont: null,
    borderRadius: null,
    businessId: null,
    businessName: null,
    createdAt: null,
    customUrl: null,
    id: null,
    logoUrl: null,
    name: null,
    normalFont: null,
    primaryColor: null,
    secondaryColor: null,
    updatedAt: null,
  },
  store: undefined,
  apiRequests: {
    businessSettings: {
      data: {
        allowOnlineTip: false,
        allowInStoreTip: false,
        businessId: null,
        convenienceFeeName: "",
        createdAt: null,
        dryCleaningEnabled: false,
        hangDryInstructions: null,
        hasConvenienceFee: false,
        id: null,
        isBagTrackingEnabled: false,
        isCustomPreferencesEnabled: false,
        isCustomUrl: false,
        isHangDryEnabled: false,
        isInStoreOnlinePaymentsEnabled: false,
        isWeightAfterProcessing: false,
        isWeightBeforeProcessing: false,
        isWeightDuringIntake: false,
        isWeightReceivingAtStore: false,
        isWeightUpOnCompletion: false,
        receiptFooterMessage: "Thank you for your order.",
        requiresEmployeeCode: false,
        requiresRack: false,
        salesWeight: null,
        termsOfServiceUrl: null,
        updatedAt: null,
        tipSettings: undefined,
        enableAutomatedLoyalty: false,
        automatedLoyaltyPointsCoef: "0",
        automatedLoyaltyCreditsCoef: "0",
        automatedLoyaltyRedemptionMilestone: 0,
        autoStartProcessing: false,
        isReferralProgramActive: false,
        businessPhoneNumber: "",
        gtmId: null,
      },
      fetchingStatus: FetchingStatus.Initial,
      error: {
        text: "",
        code: "",
      },
    },
    businessTheme: {
      data: {
        boldFont: null,
        borderRadius: null,
        businessId: null,
        businessName: null,
        createdAt: null,
        customUrl: null,
        id: null,
        logoUrl: null,
        name: null,
        normalFont: null,
        primaryColor: null,
        secondaryColor: null,
        updatedAt: null,
      },
      fetchingStatus: FetchingStatus.Initial,
      error: {
        text: "",
        code: "",
      },
    },
  },
};

export default initialData;
