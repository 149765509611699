import {httpClient} from "api";
import type {AxiosResponse} from "axios";
import {LiveLinkCustomMessage} from "state/redux/slices/order/types/orderBuilder";

/**
 * Strategies for pickup and return delivery scheduling
 */
export enum TransportationPreferences {
  /**
   * For return delivery, sets the closest time window after pickup,
   * taking into account buffer time and turn around time.
   * The customer has the possibility to select any other window.
   */
  STANDARD = "STANDARD",
  /**
   * For return delivery, sets the closest time window after pickup,
   * taking into account buffer time and turn around time,
   * and does not allow the customer to select any other window for return delivery.
   * In OOBO/MOBO, the operator is able to select the return delivery window himself.
   */
  AUTO_SCHEDULE = "AUTO_SCHEDULE",
  /**
   * By default, return delivery is set as an empty value,
   * (no return delivery) when scheduling.
   */
  TEXT_WHEN_READY = "TEXT_WHEN_READY",
}

export interface GeneralDeliverySettings extends LiveLinkCustomMessage {
  transportationPreference: TransportationPreferences;
  allowCommercialInvoicing: boolean;
  turnAroundInHours: number;
  deliveryEnabled: boolean;
}

export interface DeliverySettings {
  success: boolean;
  generalDeliverySettings: GeneralDeliverySettings;
}

export const fetchGeneralDeliverySettings = ({
  storeId,
}: {
  storeId: number;
}): Promise<AxiosResponse<DeliverySettings>> => {
  return httpClient({
    method: "GET",
    url: `/live-status/stores/${storeId}/general-delivery-settings`,
  });
};
