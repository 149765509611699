import {FC} from "react";
import {A11yButton} from "components/common/buttons/A11yButton";
import {Button} from "components/common/buttons/Button";
import {getCreditCardBrandIcon} from "utils/payment";
import {EN_LOCALE} from "locales/en";
import {Chevron, SmallWarningIcon} from "assets/images";
import styles from "./PaymentMethodButton.module.scss";

export const PaymentMethodButton: FC<{
  paymentMethodLast4Symbols: string;
  paymentMethodBrand: string;
  isRequireAddPaymentMethod?: boolean;
  toggleUpdatePayment: () => void;
  onAddPaymentMethod?: () => void;
}> = ({
  paymentMethodLast4Symbols,
  paymentMethodBrand,
  isRequireAddPaymentMethod,
  toggleUpdatePayment,
  onAddPaymentMethod,
}) => {
  return (
    <>
      <A11yButton
        onAction={toggleUpdatePayment}
        className={styles.paymentMethodContainer}
      >
        <div className={styles.title}>
          <p>{EN_LOCALE.label.payment}</p>
        </div>
        <div className={styles.cardDetails}>
          {isRequireAddPaymentMethod || !paymentMethodLast4Symbols ? (
            <img
              src={SmallWarningIcon}
              alt={EN_LOCALE.label.chevron}
              className={styles.warning}
            />
          ) : (
            <div className={styles.card}>
              <img
                src={getCreditCardBrandIcon(paymentMethodBrand)}
                width={34}
                height={22}
                alt={EN_LOCALE.label.creditCard}
                className={styles.cardIcon}
              />

              <span>
                {paymentMethodBrand} •••• {paymentMethodLast4Symbols}
              </span>
            </div>
          )}
          <img src={Chevron} alt={EN_LOCALE.label.chevron} className={styles.chevron} />
        </div>
      </A11yButton>
      {isRequireAddPaymentMethod && onAddPaymentMethod && (
        <Button className="footer-button" fullWidth onClick={onAddPaymentMethod}>
          {EN_LOCALE.button.addPayment}
        </Button>
      )}
    </>
  );
};
